import img1 from '../IMG/portfolio-tiny/1.jpg'
import img1Full from '../IMG/portfolio-large-tiny/1.jpg'
import img2 from '../IMG/portfolio-tiny/2.jpg'
import img2Full from '../IMG/portfolio-large-tiny/2.jpg'
import img3 from '../IMG/portfolio-tiny/3.jpg'
import img3Full from '../IMG/portfolio-large-tiny/3.jpg'
import img4 from '../IMG/portfolio-tiny/4.jpg'
import img4Full from '../IMG/portfolio-large-tiny/4.jpg'
import img5 from '../IMG/portfolio-tiny/5.jpg'
import img5Full from '../IMG/portfolio-large-tiny/5.jpg'
import img6 from '../IMG/portfolio-tiny/6.jpg'
import img6Full from '../IMG/portfolio-large-tiny/6.jpg'
import img7 from '../IMG/portfolio-tiny/7.jpg'
import img7Full from '../IMG/portfolio-large-tiny/7.jpg'
import img8 from '../IMG/portfolio-tiny/8.jpg'
import img8Full from '../IMG/portfolio-large-tiny/8.jpg'
import img9 from '../IMG/portfolio-tiny/9.jpg'
import img9Full from '../IMG/portfolio-large-tiny/9.jpg'
import img10 from '../IMG/portfolio-tiny/10.jpg'
import img10Full from '../IMG/portfolio-large-tiny/10.jpg'
import img11 from '../IMG/portfolio-tiny/11.jpg'
import img11Full from '../IMG/portfolio-large-tiny/11.jpg'
import img12 from '../IMG/portfolio-tiny/12.jpg'
import img12Full from '../IMG/portfolio-large-tiny/12.jpg'
import img13 from '../IMG/portfolio-tiny/13.jpg'
import img13Full from '../IMG/portfolio-large-tiny/13.jpg'
import img14 from '../IMG/portfolio-tiny/14.jpg'
import img14Full from '../IMG/portfolio-large-tiny/14.jpg'
import img15 from '../IMG/portfolio-tiny/15.jpg'
import img15Full from '../IMG/portfolio-large-tiny/15.jpg'
import img16 from '../IMG/portfolio-tiny/16.jpg'
import img16Full from '../IMG/portfolio-large-tiny/16.jpg'
import img17 from '../IMG/portfolio-tiny/17.jpg'
import img17Full from '../IMG/portfolio-large-tiny/17.jpg'
import img18 from '../IMG/portfolio-tiny/18.jpg'
import img18Full from '../IMG/portfolio-large-tiny/18.jpg'
import img19 from '../IMG/portfolio-tiny/19.jpg'
import img19Full from '../IMG/portfolio-large-tiny/19.jpg'
import img20 from '../IMG/portfolio-tiny/20.jpg'
import img20Full from '../IMG/portfolio-large-tiny/20.jpg'
import img21 from '../IMG/portfolio-tiny/21.jpg'
import img21Full from '../IMG/portfolio-large-tiny/21.jpg'
import img22 from '../IMG/portfolio-tiny/22.jpg'
import img22Full from '../IMG/portfolio-large-tiny/22.jpg'
import img23 from '../IMG/portfolio-tiny/23.jpg'
import img23Full from '../IMG/portfolio-large-tiny/23.jpg'
import img24 from '../IMG/portfolio-tiny/24.jpg'
import img24Full from '../IMG/portfolio-large-tiny/24.jpg'
import img25 from '../IMG/portfolio-tiny/25.jpg'
import img25Full from '../IMG/portfolio-large-tiny/25.jpg'
import img26 from '../IMG/portfolio-tiny/26.jpg'
import img26Full from '../IMG/portfolio-large-tiny/26.jpg'
import img27 from '../IMG/portfolio-tiny/27.jpg'
import img27Full from '../IMG/portfolio-large-tiny/27.jpg'
import img28 from '../IMG/portfolio-tiny/28.jpg'
import img28Full from '../IMG/portfolio-large-tiny/28.jpg'
import img29 from '../IMG/portfolio-tiny/29.jpg'
import img29Full from '../IMG/portfolio-large-tiny/29.jpg'
import img30 from '../IMG/portfolio-tiny/30.jpg'
import img30Full from '../IMG/portfolio-large-tiny/30.jpg'
import img31 from '../IMG/portfolio-tiny/31.jpg'
import img31Full from '../IMG/portfolio-large-tiny/31.jpg'
import img32 from '../IMG/portfolio-tiny/32.jpg'
import img32Full from '../IMG/portfolio-large-tiny/32.jpg'
import img33 from '../IMG/portfolio-tiny/33.jpg'
import img33Full from '../IMG/portfolio-large-tiny/33.jpg'
import img34 from '../IMG/portfolio-tiny/34.jpg'
import img34Full from '../IMG/portfolio-large-tiny/34.jpg'
import img35 from '../IMG/portfolio-tiny/35.jpg'
import img35Full from '../IMG/portfolio-large-tiny/35.jpg'
import img36 from '../IMG/portfolio-tiny/36.jpg'
import img36Full from '../IMG/portfolio-large-tiny/36.jpg'
import img37 from '../IMG/portfolio-tiny/37.jpg'
import img37Full from '../IMG/portfolio-large-tiny/37.jpg'
import img38 from '../IMG/portfolio-tiny/38.jpg'
import img38Full from '../IMG/portfolio-large-tiny/38.jpg'
import img39 from '../IMG/portfolio-tiny/39.jpg'
import img39Full from '../IMG/portfolio-large-tiny/39.jpg'
import img40 from '../IMG/portfolio-tiny/40.jpg'
import img40Full from '../IMG/portfolio-large-tiny/40.jpg'
import img41 from '../IMG/portfolio-tiny/41.jpg'
import img41Full from '../IMG/portfolio-large-tiny/41.jpg'
import img42 from '../IMG/portfolio-tiny/42.jpg'
import img42Full from '../IMG/portfolio-large-tiny/42.jpg'
import img43 from '../IMG/portfolio-tiny/43.jpg'
import img43Full from '../IMG/portfolio-large-tiny/43.jpg'
import img44 from '../IMG/portfolio-tiny/44.jpg'
import img44Full from '../IMG/portfolio-large-tiny/44.jpg'
import img45 from '../IMG/portfolio-tiny/45.jpg'
import img45Full from '../IMG/portfolio-large-tiny/45.jpg'
import img46 from '../IMG/portfolio-tiny/46.jpg'
import img46Full from '../IMG/portfolio-large-tiny/46.jpg'
import img47 from '../IMG/portfolio-tiny/47.jpg'
import img47Full from '../IMG/portfolio-large-tiny/47.jpg'
import img48 from '../IMG/portfolio-tiny/48.jpg'
import img48Full from '../IMG/portfolio-large-tiny/48.jpg'
import img49 from '../IMG/portfolio-tiny/49.jpg'
import img49Full from '../IMG/portfolio-large-tiny/49.jpg'
import img50 from '../IMG/portfolio-tiny/50.jpg'
import img50Full from '../IMG/portfolio-large-tiny/50.jpg'
import img51 from '../IMG/portfolio-tiny/51.jpg'
import img51Full from '../IMG/portfolio-large-tiny/51.jpg'
import img52 from '../IMG/portfolio-tiny/52.jpg'
import img52Full from '../IMG/portfolio-large-tiny/52.jpg'
import img53 from '../IMG/portfolio-tiny/53.jpg'
import img53Full from '../IMG/portfolio-large-tiny/53.jpg'
import img54 from '../IMG/portfolio-tiny/54.jpg'
import img54Full from '../IMG/portfolio-large-tiny/54.jpg'
import img55 from '../IMG/portfolio-tiny/55.jpg'
import img55Full from '../IMG/portfolio-large-tiny/55.jpg'
import img56 from '../IMG/portfolio-tiny/56.jpg'
import img56Full from '../IMG/portfolio-large-tiny/56.jpg'
import img57 from '../IMG/portfolio-tiny/57.jpg'
import img57Full from '../IMG/portfolio-large-tiny/57.jpg'
import img58 from '../IMG/portfolio-tiny/58.jpg'
import img58Full from '../IMG/portfolio-large-tiny/58.jpg'
import img59 from '../IMG/portfolio-tiny/59.jpg'
import img59Full from '../IMG/portfolio-large-tiny/59.jpg'
import img60 from '../IMG/portfolio-tiny/60.jpg'
import img60Full from '../IMG/portfolio-large-tiny/60.jpg'
import img61 from '../IMG/portfolio-tiny/61.jpg'
import img61Full from '../IMG/portfolio-large-tiny/61.jpg'
import img62 from '../IMG/portfolio-tiny/62.jpg'
import img62Full from '../IMG/portfolio-large-tiny/62.jpg'
import img63 from '../IMG/portfolio-tiny/63.jpg'
import img63Full from '../IMG/portfolio-large-tiny/63.jpg'

// const largeImages = [
//    img1Full,
//    img2Full,
//    img3Full,
//    img4Full,
//    img5Full,
//    img6Full,
//    img7Full,
//    img8Full,
//    img9Full,
//    img10Full,
//    img11Full,
//    img12Full,
//    img13Full,
//    img14Full,
//    img15Full,
//    img16Full,
//    img17Full,
//    img18Full,
//    img19Full,
//    img20Full,
//    img21Full,
//    img22Full,
//    img23Full,
//    img24Full,
//    img25Full,
//    img26Full,
//    img27Full,
//    img28Full,
//    img29Full,
//    img30Full,
//    img31Full,
//    img32Full,
//    img33Full,
//    img34Full,
//    img35Full,
//    img36Full,
//    img37Full,
//    img38Full,
//    img39Full,
//    img40Full,
//    img41Full,
//    img42Full,
//    img43Full,
//    img44Full,
//    img45Full,
//    img46Full,
//    img47Full,
//    img48Full,
//    img49Full,
//    img50Full,
//    img51Full,
//    img52Full,
//    img53Full,
//    img54Full,
//    img55Full,
//    img56Full,
//    img57Full,
//    img58Full,
//    img59Full,
//    img60Full,
//    img61Full,
//    img62Full,
//    img63Full
// ]

const images = [
   { src: img1, full: img1Full },
   { src: img2, full: img2Full },
   { src: img3, full: img3Full },
   { src: img4, full: img4Full },
   { src: img5, full: img5Full },
   { src: img6, full: img6Full },
   { src: img7, full: img7Full },
   { src: img8, full: img8Full },
   { src: img9, full: img9Full },
   { src: img10, full: img10Full },
   { src: img11, full: img11Full },
   { src: img12, full: img12Full },
   { src: img13, full: img13Full },
   { src: img14, full: img14Full },
   { src: img15, full: img15Full },
   { src: img16, full: img16Full },
   { src: img17, full: img17Full },
   { src: img18, full: img18Full },
   { src: img19, full: img19Full },
   { src: img20, full: img20Full },
   { src: img21, full: img21Full },
   { src: img22, full: img22Full },
   { src: img23, full: img23Full },
   { src: img24, full: img24Full },
   { src: img25, full: img25Full },
   { src: img26, full: img26Full },
   { src: img27, full: img27Full },
   { src: img28, full: img28Full },
   { src: img29, full: img29Full },
   { src: img30, full: img30Full },
   { src: img31, full: img31Full },
   { src: img32, full: img32Full },
   { src: img33, full: img33Full },
   { src: img34, full: img34Full },
   { src: img35, full: img35Full },
   { src: img36, full: img36Full },
   { src: img37, full: img37Full },
   { src: img38, full: img38Full },
   { src: img39, full: img39Full },
   { src: img40, full: img40Full },
   { src: img41, full: img41Full },
   { src: img42, full: img42Full },
   { src: img43, full: img43Full },
   { src: img44, full: img44Full },
   { src: img45, full: img45Full },
   { src: img46, full: img46Full },
   { src: img47, full: img47Full },
   { src: img48, full: img48Full },
   { src: img49, full: img49Full },
   { src: img50, full: img50Full },
   { src: img51, full: img51Full },
   { src: img52, full: img52Full },
   { src: img53, full: img53Full },
   { src: img54, full: img54Full },
   { src: img55, full: img55Full },
   { src: img56, full: img56Full },
   { src: img57, full: img57Full },
   { src: img58, full: img58Full },
   { src: img59, full: img59Full },
   { src: img60, full: img60Full },
   { src: img61, full: img61Full },
   { src: img62, full: img62Full },
   { src: img63, full: img63Full }
]

export default images
